<template>
  <div id="coupon">
    <div class="mine-title">
      {{ $t("route.coupon") }}
    </div>
    <van-tabs v-model="tabActive" color="#642EC7" @change="getCouponList" title-active-color="#642EC7" title-inactive-color="#666666" animated swipeable :ellipsis="false">
      <van-tab :title="title" v-for="(title, i) in tabList" :key="i">
        <div class="coupon-list">
          <div class="coupon-item col-lg-4" v-for="(item,index) in couponList" :key="index">
            <div class="coupon-wrapper" :class="{'disabled':tabActive}">
              <div class="coupon-inner">
                <div class="discount flex-center-column">
                  <div class="ellipsis bold">{{item.discount}}</div>
                  <div class="ellipsis">{{item.limit_price}}</div>
                </div>
                <div class="describe flex-center-column">
                  <div class="ellipsis bold">{{item.name}}</div>
                  <div class="ellipsis time">{{item.start_time}} - {{item.end_time}}</div>
                  <div class="ellipsis text" title="item.description">
                    {{item.description}}
                  </div>
                </div>
              </div>
              <van-image v-if="tabActive" class="img" width="100" height="100" fit="contain" :src="iconFilter()"/>
              <router-link v-else class="status flex" :class="[$i18n.locale =='en'? '' :'vertical']" to="/">
                {{$t('coupon.go')}}
              </router-link>
            </div>
          </div>
        </div>
        <div v-show="isEmpty" class="empty-wrapper flex">
          <a-empty :image="require('@/assets/coupons_none.png')">
            <span slot="description" class="empty-text"> {{$t('coupon.empty')}} </span>
          </a-empty>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { UserCouponListAPI } from '../api/api'
export default {
  name: 'Coupon',
  data() {
    return {
      isEmpty:false,
      tabActive: 0,
      tabList: [
        this.$t('coupon.tab_1'),
        this.$t('coupon.tab_2'),
        this.$t('coupon.tab_3'),
      ],
      couponList:[],
      usedIcon:{
        en:require('@/assets/coupons_icon_used_en.png'),
        cn:require('@/assets/coupons_icon_used_cn.png'),
        tw:require('@/assets/coupons_icon_used_tw.png'),
      },
      expiredIcon:{
        en:require('@/assets/coupons_icon_expired_en.png'),
        cn:require('@/assets/coupons_icon_expired_cn.png'),
        tw:require('@/assets/coupons_icon_expired_tw.png'),
      }
    }
  },
  mounted() {
    this.getCouponList()
  },
  methods: {
    iconFilter(){
      if(this.tabActive == 1){
        return this.$i18n.locale == 'en' ? this.usedIcon.en : this.$i18n.locale == 'zh-cn' ? this.usedIcon.cn : this.usedIcon.tw
      }else if(this.tabActive == 2){
        return this.$i18n.locale == 'en' ? this.expiredIcon.en : this.$i18n.locale == 'zh-cn' ? this.expiredIcon.cn : this.expiredIcon.tw
      }
    },
    async getCouponList(){
      const { data } = await UserCouponListAPI({type:this.tabActive})
      this.couponList = data
      this.isEmpty = data.length<=0
    }
  },
}
</script>
<style lang="scss" scoped>
#coupon {
  min-height: 70vh;
  .coupon-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .coupon-item{
      padding: 10px;
      .coupon-wrapper {
        height: 160px;
        background-color: #F8F4FF;
        border-radius: 10px;
        background: radial-gradient(circle at 10px, transparent 10px, #F8F4FF 0);
        background-position: 90px -80px;
        display: flex;
        .coupon-inner{
          flex: 1;
          padding: 10px 0;
          display: flex;
          color: #5D5D5D;
          font-size: 12px;
          .ellipsis{
            width: 100%;
            word-break: break-all;
            text-overflow: ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;//控制行数
            overflow: hidden;
          }
          .bold{
            color: #B50000;
            font-size: 18px;
            font-weight: bold;
          }
          .discount{
            width: 100px;
            padding: 0 10px;
            text-align: center;
            border-right: 2px dashed #fff;
          }
          .describe{
            flex: 1;
            padding: 0 10px;
            .bold{
              color: #222222;
              font-size: 14px;
              font-weight: bold;
            }
            .time{
              margin: 2px 0;
            }
            .text{
              cursor: pointer;
              line-height: 20px;
              -webkit-line-clamp:3;//控制行数
            }
          }
        }
        .status{
          width: 24px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          border-radius:0 10px 10px 0;
          background-color: #8032EA;
          text-decoration: none;
          cursor: pointer;
        }
        .vertical{
          writing-mode:vertical-lr;
          writing-mode: tb-lr;
        }
      }
      .disabled{
        background-color: #E6E6E6;
        background: radial-gradient(circle at 10px, transparent 10px, #E6E6E6 0);
        background-position: 90px -80px;
        position: relative;
        .coupon-inner{
          .bold{
            color: #5D5D5D;
          }
        }
        .status{
          color: transparent;
          background-color: #E6E6E6;
        }
        .img{
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    
  }
}
@media(max-width:768px){
  #coupon{
    .coupon-list{
      .coupon-item{
        width: 100%;
      }
    }
  }
}
</style>